import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { CreateInternalTaskDTO, LocationDTO, PickupLocationDTO, TaskTypeBaseDTO } from '../../api/api';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import { SendIcon } from '../../assets/icons/SendIcon';
import Button from '../../components/button/Button';
import DateRangePicker from '../../components/date-range-picker/DateRangePicker';
import DialogView from '../../components/dialog-view/DialogView';
import IconButton from '../../components/icon-button/IconButton';
import LoadingOverlay from '../../components/loading-overlay/LoadingOverlay';
import Select, { MenuItem } from '../../components/select/Select';
import TextField from '../../components/text-field/TextField';
import TimePicker from '../../components/time-picker/TimePicker';
import Typography from '../../components/typography/Typography';
import InternalTaskService from '../../services/InternalTaskService';
import NotificationService from '../../services/NotificationService';
import { setShouldUpdateNumberOfInternalTasksCreated } from '../../stateManagement/reducers/taskListReducer';
import { Row, Section, SectionContent, SectionHeader } from '../../styling/FormStyling';
import { ColumnFlex1 } from '../../styling/Styling';
import { log } from '../../utils/logging/log';
import { Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import AddressAutocomplete from '../../components/address-autocomplete/AddressAutocomplete';
import { LocationIcon } from '../../assets/icons/LocationIcon';
import { BoxIcon } from '../../assets/icons/BoxIcon';
import TaskTypeService from '../../services/TaskTypeService';

type InternalTaskTypeErrors = { [key in keyof CreateInternalTaskDTO]: boolean | undefined };

enum PickupLocationTypes {
  DropOff = 'Ved dropsted',
  Other = 'Anden adresse'
}
interface Props {
  internalTaskType?: TaskTypeBaseDTO;
  onClose: () => void;
}

const InternalTimeForm = (props: Props) => {
  const { onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [pickupLocations, setPickupLocations] = useState<PickupLocationDTO[]>([]);
  const [locationType, setLocationType] = useState<PickupLocationTypes>(PickupLocationTypes.DropOff);
  const [internalTaskTypes, setInternalTaskTypes] = useState<TaskTypeBaseDTO[]>([]);

  const [errors, setErrors] = useState<InternalTaskTypeErrors>();
  const [values, setValues] = useState<CreateInternalTaskDTO>({});

  const dispatch = useDispatch();

  useEffect(() => {
    TaskTypeService.getInternalTaskTypes().then((res) => setInternalTaskTypes(res));
  }, []);

  useEffect(() => {
    setIsLoading(true);
    InternalTaskService.getPickupLocations()
      .then((locations) => {
        setPickupLocations(locations);
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke hente dropsteder`);
        log(error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const clearError = useCallback((key: keyof CreateInternalTaskDTO) => {
    setErrors((errors) => ({ ...errors, [key]: false }));
  }, []);

  const handleValidate = useCallback((values: CreateInternalTaskDTO) => {
    let errors: InternalTaskTypeErrors = {};

    if (!values.taskTypeId) errors.taskTypeId = true;
    if (!values.location) errors.location = true;
    if (!values.timeFrom) errors.timeFrom = true;
    if (!values.timeTo) errors.timeTo = true;
    if (!values.normTime) errors.normTime = true;
    if (!values.description) errors.description = true;

    return errors;
  }, []);

  const handleChangeInternalTaskType = useCallback(
    (value: number) => {
      setValues((prevState) => ({ ...prevState, taskTypeId: value }));

      if (value) {
        clearError('taskTypeId');
      }
    },
    [clearError]
  );

  const handleSelectPickupLocation = useCallback(
    (city?: string, location?: PickupLocationDTO) => {
      let pickupLocation = location;

      if (city !== '') {
        pickupLocation = pickupLocations.find((pickupLocation) => pickupLocation.city === city);
      }

      if (pickupLocation) {
        const { city, latitude, longitude, houseNumber, postalCode, streetName } = pickupLocation;
        setValues((prevState) => ({
          ...prevState,
          location: { city, latitude, longitude, houseNumber, streetName, postalCode }
        }));
        clearError('location');
      } else {
        setValues((prevState) => ({
          ...prevState,
          location: undefined
        }));
      }
    },
    [pickupLocations, clearError]
  );

  const handleSelectTaskLocation = (location: LocationDTO | undefined) => {
    if (!!location) {
      handleSelectPickupLocation('', location);
    } else {
      handleSelectPickupLocation();
    }
  };

  const handleDateChange = useCallback(
    (from?: Date, to?: Date) => {
      setValues((prevState) => {
        return { ...prevState, timeFrom: from?.toISOString(), timeTo: to?.toISOString() };
      });
      clearError('timeFrom');
      clearError('timeTo');
    },
    [clearError]
  );

  const handleNormTimeChange = useCallback(
    (hours: number, minutes: number) => {
      const totalMinutes = hours * 60 + minutes;
      setValues((prevState) => ({ ...prevState, normTime: totalMinutes }));
      if (hours || minutes) {
        clearError('normTime');
      }
    },
    [clearError]
  );

  const handleChangeDescription = useCallback(
    (value: string) => {
      setValues((prevState) => ({ ...prevState, description: value }));
      if (value) {
        clearError('description');
      }
    },
    [clearError]
  );

  const handleSubmit = useCallback(() => {
    setErrors(undefined);
    const errors = handleValidate(values);

    if (Object.keys(errors).length) return setErrors(errors);

    setIsLoading(true);
    InternalTaskService.createInternalTask(values)
      .then(() => {
        dispatch(setShouldUpdateNumberOfInternalTasksCreated(true));

        NotificationService.success('Intern opgave forespørgsel sendt');
        onClose();
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke sende opgave forespørgsel: ${error}`);
        log(error);
      })
      .finally(() => setIsLoading(false));
  }, [handleValidate, values, dispatch, onClose]);

  return (
    <DialogView handleClose={onClose}>
      <Container>
        {isLoading && <LoadingOverlay />}
        <Header>
          <Typography variant="h4" fontWeight="bold">
            Opret intern opgave
          </Typography>
          <IconButton variant="outlined" onClick={onClose}>
            <CloseIcon size="18px" />
          </IconButton>
        </Header>
        <Section>
          <StyledSelect>
            <Select
              onChange={(e) => handleChangeInternalTaskType(e.target.value as number)}
              error={errors?.taskTypeId}
              fullWidth
              label="Vælg opgavetype"
              value={values.taskTypeId}
              required
            >
              {internalTaskTypes.map((taskType) => (
                <MenuItem key={taskType.name} value={taskType.id}>
                  {taskType.name}
                </MenuItem>
              ))}
            </Select>
          </StyledSelect>
          <SectionHeader>Vælg hvor opgaven skal udføres</SectionHeader>
          <StyledRadioGroup row>
            <FormControlLabel
              value={PickupLocationTypes.DropOff}
              control={<StyledRadio />}
              label={
                <StyledLabel>
                  <BoxIcon size="36px" margin="0 5px 0 0" />
                  <StyledLabelText>
                    <Typography fontWeight="bold"> {PickupLocationTypes.DropOff}</Typography>
                    <Typography>Vælg dropsted fra liste</Typography>
                  </StyledLabelText>
                </StyledLabel>
              }
              checked={locationType === PickupLocationTypes.DropOff}
              onChange={() => setLocationType(PickupLocationTypes.DropOff)}
              labelPlacement="start"
              sx={{ alignItems: 'flex-start' }}
            />
            <Divider orientation="vertical" variant="middle" sx={{ width: '2px', height: '40px', marginLeft: '15px' }} />
            <FormControlLabel
              value={PickupLocationTypes.Other}
              control={<StyledRadio />}
              label={
                <StyledLabel>
                  <LocationIcon size="36px" margin="0 5px 0 0" />
                  <StyledLabelText>
                    <Typography fontWeight="bold">{PickupLocationTypes.Other}</Typography>
                    <Typography>Angiv adressen hvor opgaven udføres</Typography>
                  </StyledLabelText>
                </StyledLabel>
              }
              checked={locationType === PickupLocationTypes.Other}
              onChange={() => setLocationType(PickupLocationTypes.Other)}
              labelPlacement="start"
              sx={{ alignItems: 'flex-start' }}
            />
          </StyledRadioGroup>

          {locationType === PickupLocationTypes.DropOff && (
            <SectionContent>
              <Select
                onChange={(e) => handleSelectPickupLocation(e.target.value)}
                label="Vælg dropsted"
                value={values?.location?.city || ''}
                required={locationType === PickupLocationTypes.DropOff}
                fullWidth
                error={errors?.location}
              >
                {pickupLocations?.map((location, i) => {
                  return (
                    <MenuItem value={location.city} key={i}>
                      {location.city}
                    </MenuItem>
                  );
                })}
              </Select>
            </SectionContent>
          )}

          {locationType === PickupLocationTypes.Other && (
            <AddressAutocomplete
              onSelect={handleSelectTaskLocation}
              label="Adresse"
              noOptionsText="Ingen resultater"
              required={locationType === PickupLocationTypes.Other}
            />
          )}
        </Section>
        <Section>
          <SectionHeader>Vælg ønsket tid for aftalen og forventet tidsforbrug</SectionHeader>
          <SectionContent>
            <StyledRow>
              <ColumnFlex1>
                <StyledDateRangePicker
                  error={errors?.timeFrom && errors?.timeTo}
                  onDateChanged={handleDateChange}
                  centerY
                  centerX
                  label="Tidsinterval"
                  helperText="Angiv ønsket tidsinterval"
                  required
                />
              </ColumnFlex1>
              <ColumnFlex1>
                <TimePicker
                  initialHours={0}
                  initialMinutes={0}
                  error={errors?.normTime}
                  onTimeChange={handleNormTimeChange}
                />
              </ColumnFlex1>
            </StyledRow>
            <TextField
              error={errors?.description}
              value={values.description || ''}
              onChange={(e) => handleChangeDescription(e.target.value)}
              multiline
              maxRows={4}
              minRows={4}
              label="Uddybende beskrivelse"
              variant="outlined"
              placeholder="Angiv en kort beskrivelse af opgaven"
              required
              fullWidth
            />
          </SectionContent>
        </Section>

        <Footer>
          <SubmitButton onClick={handleSubmit}>
            <SendIcon size="20px" />
            Send forespørgsel
          </SubmitButton>
        </Footer>
      </Container>
    </DialogView>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 12px 24px;
  background-color: white;
  width: 700px;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  column-gap: 32px;
`;

const StyledRadio = styled(Radio)`
  margin: 15px;
`;

const StyledLabel = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  height: 70px;
`;

const StyledLabelText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const StyledDateRangePicker = styled(DateRangePicker)`
  & .MuiFormHelperText-root {
    position: absolute;
    top: 100%;
    margin: 3px 0;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 20px;
`;

const Footer = styled.div`
  position: sticky;
  margin-top: auto;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.main};

  background: #fff;
  margin: auto -24px 0 -24px;
  padding: 12px 24px 4px 24px;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

const SubmitButton = styled(Button)`
  float: right;
`;

const StyledSelect = styled.div`
  margin-top: ${(props) => props.theme.spacing(6)};
`;

export default InternalTimeForm;
