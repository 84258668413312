import React from 'react';
import styled, { css } from 'styled-components';
import IconButton from '../../components/icon-button/IconButton';
import { CloseIcon } from '../../assets/icons/CloseIcon';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import TabsVerticalTools from './tabs-vertical-tools/TabsVerticalTools';
import Typography from '../../components/typography/Typography';
import ToolTip from '../../components/tool-tip/ToolTip';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { RemoveIcon } from '../../assets/icons/RemoveIcon';

export interface TabProps {
  contentHeaderText?: JSX.Element;
  tabHeaderText?: string;
  tabSubtitleText?: string;
  tabId?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  notification?: JSX.Element;
  hideScrollbar?: boolean;
  footerComponent?: React.ReactNode;
}

interface Props {
  children?: React.ReactNode;
  headerComponent?: React.ReactNode;
  contentLoading?: boolean;
  activePanelId: string;
  handleActivePanelIdChange: (id: string) => void;
  onMinimize?: () => void;
  onClose?: () => void;
  tools?: React.ReactNode;
  toolsOpen?: boolean;
  setToolsOpen?: (open: boolean) => void;
  openBy?: string[];
}

const TabsVertical = (props: Props) => {
  const {
    headerComponent,
    contentLoading,
    activePanelId,
    onMinimize,
    handleActivePanelIdChange,
    onClose,
    tools,
    toolsOpen,
    setToolsOpen,
    openBy
  } = props;

  const arrayChildren = React.Children.toArray(props.children);

  const renderMenuTabs = () => {
    return arrayChildren.map((child, i) => {
      const tabHeaderText = (child as any).props?.tabHeaderText ?? 'Item ' + i;
      const hidden = (child as any).props?.hidden;
      const notification = (child as any).props?.notification;

      if (hidden) return null;

      return (
        <MenuTabContainer key={i} active={isActiveChild(child)}>
          <MenuTab
            onClick={() => handleActivePanelIdChange((child as any).props?.tabId)}
            data-testid={'tabs-vertical-menu-' + tabHeaderText}
          >
            {tabHeaderText}
          </MenuTab>
          <NotificationContainer>{notification}</NotificationContainer>
        </MenuTabContainer>
      );
    });
  };

  const renderContent = () => {
    const child = arrayChildren.find((child) => isActiveChild(child));

    if (child) {
      const childHeader = (child as any)?.props?.contentHeaderText ?? '';
      const hidden = (child as any)?.props?.hidden;
      const childFooter = (child as any)?.props?.footerComponent ?? undefined;

      return (
        <>
          <ContentHeader>
            <ContentHeaderLeft>
              {hidden && (
                <ContentHeaderBackButton variant="standard" onClick={() => handleActivePanelIdChange('1')}>
                  <ChevronLeft size="16px" />
                </ContentHeaderBackButton>
              )}
              {childHeader}
            </ContentHeaderLeft>
            <HeaderButtonContainer>
              {openBy !== undefined && openBy.length > 0 && (
                <OpenBy>
                  <Typography variant="p" fontWeight="bold">
                    Har opgaven åben:
                  </Typography>
                  {openBy.length < 3 && <Typography noWrap>{openBy.join(', ')}</Typography>}
                  {openBy.length >= 3 && (
                    <ToolTip title={openBy.join(', ')}>
                      <div>{openBy.length} personer</div>
                    </ToolTip>
                  )}
                </OpenBy>
              )}
              {!!onMinimize && (
                <IconButton variant="outlined" onClick={onMinimize}>
                  <RemoveIcon size="18px" />
                </IconButton>
              )}
              {onClose && (
                <IconButton variant="outlined" onClick={onClose}>
                  <CloseIcon size="18px" />
                </IconButton>
              )}
            </HeaderButtonContainer>
          </ContentHeader>
          <ContentBody id="task-details-body" hideScrollbar={(child as any)?.props?.hideScrollbar}>
            {child}
            {childFooter && <ContentFooterContainer>{childFooter}</ContentFooterContainer>}
          </ContentBody>
        </>
      );
    }
  };

  const isActiveChild = (child: React.ReactChild | React.ReactFragment | React.ReactPortal): boolean => {
    return (child as any).props?.tabId === activePanelId;
  };

  return (
    <Container>
      <Menu>
        {!!headerComponent && <MenuHeader>{headerComponent}</MenuHeader>}
        {renderMenuTabs()}

        {tools && setToolsOpen && (
          <TabsVerticalTools open={toolsOpen ?? false} setOpen={setToolsOpen}>
            {tools}
          </TabsVerticalTools>
        )}
      </Menu>
      <Content>{contentLoading === true ? <CircularProgress /> : renderContent()}</Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  background-color: #ffffff;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-width: 200px;
  max-width: 200px;
  overflow-wrap: anywhere;
  padding: 24px;

  background: ${(props) => props.theme.palette.grey.black5};

  @media (max-width: 1200px) {
    min-width: 165px;
  }
`;

const MenuHeader = styled.div`
  min-height: 1rem;
  padding-bottom: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey.black10};
`;

const activeStyles = css`
  color: ${(props) => props.theme.palette.text.secondary};
  background: ${(props) => props.theme.palette.grey.black60};
  border-radius: 8px;

  padding: 0.5rem;
  margin: -0.5rem;
`;

const MenuTabContainer = styled.div<{ active?: boolean }>`
  display: flex;
  color: ${(props) => props.theme.palette.grey.black60};
  ${(props) => props.active && activeStyles};
`;

const NotificationContainer = styled.div`
  align-self: center;
  display: flex;
`;

const MenuTab = styled.h3`
  font-size: 18px;
  font-weight: 800;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 24px 32px;
  max-width: calc(100% - 214px);
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;

  border-bottom: 1px solid ${(props) => props.theme.palette.grey.black10};
  margin-right: -32px;
  padding-right: 32px;
`;

const ContentHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`;

const ContentHeaderBackButton = styled(IconButton)`
  margin-right: 10px;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

const ContentBody = styled.div<{ hideScrollbar?: boolean }>`
  height: calc(100% - 66px);
  display: flex;
  flex-direction: column;
  overflow-y: ${(props) => (props.hideScrollbar ? '' : 'scroll')};
  overflow-x: hidden;
  margin-right: -32px;
  padding-right: 24px;
`;

export const ContentFooterContainer = styled.div`
  position: sticky;
  margin-top: auto;
  bottom: 0;
  z-index: ${(props) => props.theme.zIndex.main};

  background: #fff;
  margin-right: -32px;
  padding: 24px 24px 4px 12px;
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
`;

const OpenBy = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
`;

export default TabsVertical;
