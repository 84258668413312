import styled from 'styled-components';
import { ChevronUp } from '../../../../assets/icons/ChevronUp';
import { ChevronDown } from '../../../../assets/icons/ChevronDown';
import Typography from '../../../../components/typography/Typography';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import Button from '../../../../components/button/Button';
import InformationBox from '../../../../components/information-box/InformationBox';
import { PeopleIcon } from '../../../../assets/icons/PeopleIcon';
import { ClipboardIcon } from '../../../../assets/icons/ClipboardIcon';
import StatusIndicator, { STATUS } from '../../../../components/status-indicator/StatusIndicator';
import ToolTip from '../../../../components/tool-tip/ToolTip';

interface Props {
  header: string;
  isChecklist?: boolean;
  isCollapsable: boolean;
  isRepeatable?: boolean;
  isExpanded?: boolean;
  setIsExpanded?: () => void;
  showError?: boolean;
  onDelete?: () => void;
  checkListChanges?: boolean;
  openBy?: string[];
  readOnly?: boolean;
  subSectionsLength?: number;
  showIsCompleted?: boolean;
  isCompleted?: boolean;
  toggleIsCompleted?: () => void;
  children: React.ReactNode | React.ReactNode[];
  id?: number;
}

const ExpandCollapseTaskChecklistHeaders = (props: Props) => {
  const {
    header,
    isChecklist = false,
    isCollapsable,
    isRepeatable,
    isExpanded = true,
    setIsExpanded,
    onDelete,
    checkListChanges,
    showError,
    openBy,
    readOnly,
    subSectionsLength,
    showIsCompleted = false,
    isCompleted,
    toggleIsCompleted,
    children,
    id
  } = props;

  const onClickStatusIndicator: React.MouseEventHandler = (e) => {
    if (toggleIsCompleted) {
      e.stopPropagation();
      toggleIsCompleted();
    }
  };

  return (
    <Container id={`checklist-id-${id}`} isRepeatableSection={!isChecklist && isRepeatable}>
      <Header onClick={setIsExpanded}>
        <LeftHeader>
          {showIsCompleted && (
            <StatusIndicator
              status={isCompleted ? STATUS.GREEN : STATUS.GREY}
              interactive
              onClick={(e) => onClickStatusIndicator(e)}
            />
          )}
          <Typography variant={isChecklist ? 'h4' : 'h5'} fontWeight="bold">
            {header}
          </Typography>
          {checkListChanges && (
            <InformationBox variant="tertiary">
              <Typography noWrap>Ændringer fortaget</Typography>
            </InformationBox>
          )}
          {openBy !== undefined && openBy.length > 0 && (
            <InformationBox variant="secondary" icon={<PeopleIcon size="16px" />}>
              {openBy.length < 4 && <Typography noWrap>{openBy.join(', ')}</Typography>}
              {openBy.length >= 4 && (
                <ToolTip title={openBy.join(', ')}>
                  <div>{openBy.length} personer</div>
                </ToolTip>
              )}
            </InformationBox>
          )}
        </LeftHeader>
        {isCollapsable && (
          <RightHeader>
            {isRepeatable && !readOnly && isExpanded && (
              <StyledButton variant="secondary" onClick={() => onDelete && onDelete()}>
                <DeleteIcon size="20px" />
                Fjern
              </StyledButton>
            )}
            {showError && !isExpanded && !readOnly && <InformationBox variant="error">Obligatoriske felter</InformationBox>}

            {!!subSectionsLength && (
              <InformationBox>
                <StyledClipboardIcon color="white" size="14px" />
                {subSectionsLength}
              </InformationBox>
            )}
            {isExpanded ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
          </RightHeader>
        )}
      </Header>

      {isExpanded ? children : ''}
    </Container>
  );
};

export default ExpandCollapseTaskChecklistHeaders;

const Container = styled.div<{ isRepeatableSection?: boolean }>`
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${(props) => props.theme.palette.grey.black10};
  background-color: ${(props) => (props.isRepeatableSection ? props.theme.palette.background.tertiary : '')};

  margin-top: ${(props) => props.theme.spacing(6)};
  padding: ${(props) => props.theme.spacing(6)};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const RightHeader = styled.div`
  display: flex;
  align-items: right;
  text-align: left;
  align-items: center;
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(4)};
`;
const StyledClipboardIcon = styled(ClipboardIcon)`
  vertical-align: text-top;
  margin-right: ${(props) => props.theme.spacing(1)};
`;
