import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import useNewsList from './useNewsList';
import Typography from '../../../components/typography/Typography';
import { AddIcon } from '../../../assets/icons/AddIcon';
import AdminCreateNewsDialog from './AdminCreateNewsDialog';
import Table from '../../../blocks/table/TableClient';
import { Header } from '../../goods-view/GoodsView';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import { NewsDTO } from '../../../api/api';
import NewsService from '../../../services/NewsService';
import NotificationService from '../../../services/NotificationService';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import { log } from '../../../utils/logging/log';

const dialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText: 'Er du sikker på at du vil slette denne nyhed?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const AdminNewsView = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [allNews, setAllNews] = useState<NewsDTO[]>([]);
  const { getConfirmation } = useConfirmationDialog();
  const [editingNews, setEditingNews] = useState<NewsDTO>();
  const [loading, setLoading] = useState(false);

  const createNewsObjectCallback = (newNewsListObject: NewsDTO) => {
    setAllNews((prev) => [...prev, newNewsListObject]);
    setShowDialog(false);
  };

  const editNewsCallback = (updatedNews: NewsDTO) => {
    setAllNews((prev) => prev.map((_news) => (_news.id === updatedNews.id ? (updatedNews as NewsDTO) : _news)));
  };

  const deleteNewsClickedHandler = async (id: number) => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      setLoading(true);
      NewsService.deleteNews(id)
        .then(() => {
          setAllNews((prev) => prev.filter((news) => news.id !== id));
        })
        .catch((e) => {
          log(e);
          NotificationService.error('Kunne ikke slette nyhed');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const editNewsClickedHandler = async (news: NewsDTO) => {
    setEditingNews(news);
    setShowDialog(true);
  };

  useEffect(() => {
    setLoading(true);
    NewsService.getAllNews()
      .then((response) => {
        setAllNews(response);
      })
      .catch((e) => {
        log(e);
        NotificationService.error('Kunne ikke hente nyheder');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const { tableInstance } = useNewsList(allNews, deleteNewsClickedHandler, editNewsClickedHandler);

  return (
    <Container>
      <Header>
        <Typography variant="h3">Nyheder</Typography>
        <Button onClick={() => setShowDialog(true)}>
          <AddIcon size="20px" />
          Opret ny besked
        </Button>
      </Header>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <>
          <Table loading={false} tableInstance={tableInstance} showPagination />
          <AdminCreateNewsDialog
            setShowDialog={() => setShowDialog(false)}
            showDialog={showDialog}
            createNewsObjectCallback={createNewsObjectCallback}
            editingNews={editingNews}
            editNewsObjectCallback={editNewsCallback}
            resetEditingNews={() => setEditingNews(undefined)}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding: ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(7)} 0px
      ${(props) => props.theme.spacing(7)};
  }
`;

export default AdminNewsView;
