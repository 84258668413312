import { CheckmarkIcon } from '../../../assets/icons/CheckmarkIcon';
import { CloseIcon } from '../../../assets/icons/CloseIcon';
import styled from 'styled-components';
import { DownloadIcon } from '../../../assets/icons/DownloadIcon';
import Button from '../../../components/button/Button';
import { useDispatch } from 'react-redux';
import { setShowResultsTable } from '../../../stateManagement/reducers/massCreationReducer';
import theme from '../../../theme/light-theme';

interface Props {
  numberOfErrors: number;
}

const ResultLabel = (props: Props) => {
  const { numberOfErrors } = props;

  const text = numberOfErrors === 1 ? 'række' : 'rækker';
  const dispatch = useDispatch();
  return (
    <FullWidthRow>
      {numberOfErrors > 0 ? (
        <StatusLabel numberOfErrors={numberOfErrors}>
          <IconContainer numberOfErrors={numberOfErrors}>
            <CloseIcon color={theme.palette.background.primary} size="13px" />
          </IconContainer>
          <p>
            <b>{numberOfErrors}</b> {text} fejlede. Tjek log og prøv igen
          </p>
        </StatusLabel>
      ) : (
        <>
          <StatusLabel numberOfErrors={numberOfErrors}>
            <IconContainer numberOfErrors={numberOfErrors}>
              <CheckmarkIcon color={theme.palette.background.primary} size="14px" />
            </IconContainer>
            Succes. Alle assets fundet
          </StatusLabel>
        </>
      )}
      <div className="vis-output">
        <OutputButton variant="secondary" onClick={() => dispatch(setShowResultsTable(true))}>
          <DownloadIcon size="14px" />
          Vis output
        </OutputButton>
      </div>
    </FullWidthRow>
  );
};

const FullWidthRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  height: 30px;
`;

const StatusLabel = styled.label<{ numberOfErrors: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ numberOfErrors, theme }) =>
    numberOfErrors > 0 ? theme.palette.functions.error.tertiary : theme.palette.functions.success.tertiary};
  border-radius: 10px;
  padding: 0px 10px;
  height: 30px;
`;

const IconContainer = styled.div<{ numberOfErrors: number }>`
  background-color: ${({ numberOfErrors, theme }) =>
    numberOfErrors > 0 ? theme.palette.functions.error.primary : theme.palette.functions.success.primary};
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;

const OutputButton = styled(Button)`
  padding: 16px;
  border-radius: 10px;
  font-weight: 600;
  height: 30px;
  font-size: 14px;
`;
export default ResultLabel;
