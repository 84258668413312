import styled from 'styled-components';
import Typography from '../../../components/typography/Typography';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { SelectedGoods } from '../../../models/Goods';

interface Props {
  selectedGoods: SelectedGoods[];
  removeGoods: (goodsId?: string) => void;
}

const BasketPopover = (props: Props) => {
  const { selectedGoods, removeGoods } = props;

  return (
    <BaskeContainer>
      <BasketColumn>
        <Typography variant="h5" fontWeight="bold">
          Din kurv
        </Typography>
      </BasketColumn>
      <BasketRow hideBorder>
        <BasketColumn>
          <Typography fontSize={10} color="greyedOut">
            Navn
          </Typography>
        </BasketColumn>
        <BasketElement>
          <Typography fontSize={10} color="greyedOut">
            Antal
          </Typography>
        </BasketElement>
      </BasketRow>
      {selectedGoods.map((sg, i) => {
        return (
          <BasketRow hideBorder={!selectedGoods[i + 1]}>
            <BasketColumn>
              <Typography>
                {sg.name} {<Typography color="greyedOut">(varenr. {sg.itemId})</Typography>}
              </Typography>
            </BasketColumn>
            <BasketElement>
              <Typography>{sg.qty}</Typography>
            </BasketElement>
            <BasketElement>
              <Clickable onClick={() => removeGoods(sg.itemId)}>
                <StyledDeleteIcon size="20px" />
              </Clickable>
            </BasketElement>
          </BasketRow>
        );
      })}
    </BaskeContainer>
  );
};

const BaskeContainer = styled.div`
  max-height: 1000px;
  overflow-y: scroll;
  width: 450px;
  padding: ${(props) => props.theme.spacing(4)};
  radius: 20px;
  border: 1px;
  border-color: ${(props) => props.theme.palette.main.black};
`;

const BasketRow = styled.div<{ hideBorder?: boolean }>`
  margin-top: ${(props) => props.theme.spacing(2)};
  display: flex;
  border-bottom: ${(props) => (props.hideBorder ? '' : `1px solid ${props.theme.palette.grey.black10}`)};
`;

const Clickable = styled.div`
  cursor: pointer;
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  align: right;
`;

const BasketColumn = styled.div`
  width: 250px;
`;

const BasketElement = styled.div`
  display: flex;
  width: 100px;
  flex-direction: row-reverse;
  align-items: center;
`;

export default BasketPopover;
