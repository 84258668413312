import { useState } from 'react';
import styled from 'styled-components';
import InternalTimeForm from '../../blocks/internal-time-form/InternalTimeForm';
import InternalTaskList from './internal-task-list/InternalTaskList';
import Button from '../../components/button/Button';
import { AddIcon } from '../../assets/icons/AddIcon';
const InternalTimeOfficeView = () => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <Container>
      <ButtonContainer>
        <Button onClick={() => setDialogOpen(true)}>
          <AddIcon size="20px" />
          Opret intern opgave
        </Button>
      </ButtonContainer>
      <InternalTaskList />
      {dialogOpen && <InternalTimeForm onClose={() => setDialogOpen(false)} />}
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;

  & > *:nth-child(1) {
    padding-left: ${(props) => props.theme.spacing(4)};
    padding-right: ${(props) => props.theme.spacing(4)};
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
  margin-right: ${(props) => props.theme.spacing(4)};
  display: flex;
  flex-direction: row-reverse;
`;

export default InternalTimeOfficeView;
