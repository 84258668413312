import { TabProps } from '../../../../../blocks/tabs-vertical/TabsVertical';
import Sections from './Sections';
import {
  QuestionDTO,
  SectionQuestionTextDTO,
  TaskTypeCheckListTextDTO,
  TaskTypeSectionTextDTO
} from '../../../../../api/api';
import styled from 'styled-components';
import Typography from '../../../../../components/typography/Typography';
import Checkbox from '../../../../../components/checkbox/Checkbox';
import Button from '../../../../../components/button/Button';
import { SettingsIcon } from '../../../../../assets/icons/SettingsIcon';
import { useState } from 'react';
import { useConfirmationDialog } from '../../../../../hooks/useConfirmationDialog';
import { DialogBody } from '../../../../../stateManagement/reducers/confirmDialogReducer';
import InformationBox from '../../../../../components/information-box/InformationBox';
import { EditIcon } from '../../../../../assets/icons/EditIcon';
import { CheckmarkIcon } from '../../../../../assets/icons/CheckmarkIcon';
import TextField from '../../../../../components/text-field/TextField';
import { CloseIcon } from '../../../../../assets/icons/CloseIcon';
import AdminService from '../../../../../services/AdminService';
import { log } from '../../../../../utils/logging/log';
import NotificationService from '../../../../../services/NotificationService';
import CircularProgress from '../../../../../components/circular-progress/CircularProgress';

const navigateDialogBody: DialogBody = {
  headerText: 'Er du sikker?',
  bodyText:
    'Er du sikker på at du vil slette denne tjekliste? Alle underligggende sektioner og tjeklister vil blive slettet.',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface Props extends TabProps {
  index: number;
  checklist: TaskTypeCheckListTextDTO;
  setChecklist: (checklist: TaskTypeCheckListTextDTO) => void;
  setSections: (sections: TaskTypeSectionTextDTO[], newSection?: number) => void;
  setAddQuestionToSection: (sectiond: SectionQuestionTextDTO) => void;
  setPreview: () => void;
  allQuestions: QuestionDTO[];
  deleteChecklist: () => void;
  swapChecklists: (checklistIndex1: number, checklistIndex2: number) => void;
}

const TaskTypeChecklist = (props: Props) => {
  const {
    index,
    checklist,
    setChecklist,
    setSections,
    setAddQuestionToSection,
    allQuestions,
    setPreview,
    deleteChecklist,
    swapChecklists
  } = props;
  const sections = checklist.taskTypeSections;
  const { getConfirmation } = useConfirmationDialog();

  const [showChecklistConfig, setShowChecklistConfig] = useState(false);
  const [isEditingChecklistName, setIsEditingChecklistName] = useState(false);
  const [editedChecklistName, setEditedChecklistName] = useState(checklist.checkListName);
  const [isLoading, setIsLoading] = useState(false);

  const removeChecklistHandler = async () => {
    if (!checklist) return;
    const confirmation = await getConfirmation(navigateDialogBody);
    if (confirmation === 'confirm') {
      deleteChecklist();
    }
  };

  const confirmEdit = () => {
    setIsLoading(true);

    AdminService.updateTaskTypeCheckListName(checklist.taskTypeCheckListId!, editedChecklistName!)
      .then(() => {
        setChecklist({ ...checklist, checkListName: editedChecklistName });
        setIsEditingChecklistName(false);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke opdatere navnet på tjeklisten');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const cancelEdit = () => {
    setEditedChecklistName(checklist.checkListName);
    setIsEditingChecklistName(false);
  };

  return (
    <ChecklistContainter>
      <StyledHeaderContainer>
        {!isEditingChecklistName && (
          <LeftRow>
            <Typography variant="h4">{checklist.checkListName}</Typography>
            <Clickable onClick={() => setIsEditingChecklistName(true)}>
              <EditIcon />
            </Clickable>
          </LeftRow>
        )}
        {isEditingChecklistName && (
          <LeftRow>
            <TextField
              fullWidth
              value={editedChecklistName}
              label="Navn"
              onChange={(e) => setEditedChecklistName(e.target.value)}
            />
            {isLoading && (
              <Clickable>
                <StyledCircularProgress size="1.5rem" />
              </Clickable>
            )}
            {!isLoading && (
              <>
                <Clickable onClick={confirmEdit}>
                  <CheckmarkIcon />
                </Clickable>
                <Clickable onClick={cancelEdit}>
                  <CloseIcon />
                </Clickable>
              </>
            )}
          </LeftRow>
        )}
        <RightRow>
          <StyledButton onClick={setPreview} variant="secondary" compact>
            Se preview
          </StyledButton>
          {checklist.isRepeatable && <InformationBox>Gentagbar</InformationBox>}
          <div onClick={() => setShowChecklistConfig(!showChecklistConfig)}>
            <SettingsIcon size="24px" />
          </div>
        </RightRow>
      </StyledHeaderContainer>
      {showChecklistConfig && (
        <StyledSectionConfig>
          <Typography variant="h5" fontWeight="bold">
            Indstillinger
          </Typography>
          <Content>
            <TextField
              fullWidth
              value={checklist.checkListHeader}
              label="Overskrift"
              onChange={(e) => setChecklist({ ...checklist, checkListHeader: e.target.value })}
            />
            <Checkbox
              label={'Tjeklisten gentager sig'}
              checked={checklist.isRepeatable}
              onChange={(e) => setChecklist({ ...checklist, isRepeatable: !checklist.isRepeatable })}
            />
          </Content>
          <Content>
            <Row>
              <RowLeft>
                <Button variant="secondary" compact onClick={() => swapChecklists(index, index - 1)}>
                  Ryk op
                </Button>
                <Button variant="secondary" compact onClick={() => swapChecklists(index + 1, index)}>
                  Ryk ned
                </Button>
              </RowLeft>
              <RowRight>
                <Button variant="secondary" compact onClick={removeChecklistHandler}>
                  Fjern tjekliste
                </Button>
              </RowRight>
            </Row>
          </Content>
        </StyledSectionConfig>
      )}
      <Sections
        sections={sections ?? []}
        addQuestion={(section: TaskTypeSectionTextDTO) => setAddQuestionToSection(section)}
        setSections={(sections, newSection) => setSections(sections, newSection)}
        allQuestions={allQuestions}
      />
    </ChecklistContainter>
  );
};

export default TaskTypeChecklist;

const LeftRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding-right: ${(props) => props.theme.spacing(16)};
`;

const Clickable = styled.div`
  cursor: pointer;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing(4)};
`;

const ChecklistContainter = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${(props) => props.theme.palette.grey.black10};

  margin-top: ${(props) => props.theme.spacing(6)};
  padding: ${(props) => props.theme.spacing(6)};
`;

const StyledSectionConfig = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(8)};
  padding: ${(props) => props.theme.spacing(6)} ${(props) => props.theme.spacing(6)} 0 ${(props) => props.theme.spacing(6)};
  background-color: ${(props) => props.theme.palette.background.tertiary};
`;

const Content = styled.div`
  margin-top: ${(props) => props.theme.spacing(6)};
  padding-bottom: ${(props) => props.theme.spacing(6)};
  border-bottom: 1px solid #e3e3e3;

  > div {
    padding-bottom: ${(props) => props.theme.spacing(4)};
  }
`;
const Row = styled.div`
  margin-top: ${(props) => props.theme.spacing(4)};
  display: flex;
  justify-content: space-between;
  &:first-child {
    margin-top: 0;
  }
`;
const RowLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  column-gap: ${(props) => props.theme.spacing(6)};
`;

const RowRight = styled.div`
  margin-left: auto;
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const RightRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
`;

const StyledButton = styled(Button)`
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const StyledCircularProgress = styled(CircularProgress)`
  margin: 0px;
  display: inline-block;
  padding-left: ${(props) => props.theme.spacing(6)};
`;
