import styled from 'styled-components';
import { InfoIcon } from '../../assets/icons/InfoIcon';
import { CheckmarkCircleIcon } from '../../assets/icons/CheckmarkCircleIcon';
import { WarningIcon } from '../../assets/icons/WarningIcon';
import theme from '../../theme/light-theme';

export type Severity = 'error' | 'warning' | 'action' | 'success';

interface Props {
  children: React.ReactNode;
  severity: Severity;
  height?: string;
  width?: string;
  margin?: string;
}

const getSeverityElements = (
  severity: Severity
): { icon: JSX.Element; color: string; backgroundColor?: string; borderColor?: string } => {
  switch (severity) {
    case 'error':
      return {
        icon: <WarningIcon size="18px" color={theme.palette.functions.error.primary} />,
        color: theme.palette.functions.error.primary,
        backgroundColor: theme.palette.functions.error.tertiary
      };
    case 'action':
      return {
        icon: <InfoIcon size="18px" color={theme.palette.functions.action.primary} />,
        color: theme.palette.functions.action.secondary,
        backgroundColor: theme.palette.functions.action.tertiary
      };
    case 'warning':
      return {
        icon: <WarningIcon size="18px" color={theme.palette.functions.warning.primary} />,
        color: theme.palette.functions.warning.secondary,
        backgroundColor: theme.palette.functions.warning.tertiary,
        borderColor: theme.palette.functions.warning.primary
      };
    case 'success':
      return {
        icon: <CheckmarkCircleIcon size="18px" color={theme.palette.functions.success.primary} />,
        color: theme.palette.functions.success.secondary,
        backgroundColor: theme.palette.functions.success.tertiary
      };
  }
};

const Alert = (props: Props) => {
  const { children, severity, height, width, margin } = props;

  const { color, backgroundColor, borderColor, icon } = getSeverityElements(severity);

  return (
    <Container
      color={color}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      height={height}
      width={width}
      margin={margin}
      data-testid="success-alert"
    >
      <Column>{icon}</Column>
      <Column>{children}</Column>
    </Container>
  );
};

interface ContainerProps {
  color: string;
  backgroundColor?: string;
  borderColor?: string;
  height?: string;
  width?: string;
  top?: string;
  bottom?: string;
  margin?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 0px 12px;
  align-items: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  column-gap: 12px;
  border: 1px solid ${(props) => props.borderColor ?? props.color};
  border-radius: 8px;
  margin: ${(props) => props.margin ?? ''};
`;

const Column = styled.div``;
export default Alert;
